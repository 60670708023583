import React, { Component } from 'react'
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";

class Mint extends Component {

    constructor(props) {
        super(props);
        this.state = {
            qtyWhiteList: 1,
            qtyPublic: 1,
        }
    }

    render() {

        console.log(
            {
            count: this.state.qtyWhiteList,
            wl: 1,
            quantity: this.state.qtyWhiteList,
            /*totalPrice: "" + this.state.qtyWhiteList * window.web3.utils.fromWei(this.props.saleConfig.mintlistPrice) + "",*/
            to: "" + this.props.currentWallet + ""
            }
        );

        let htmlMint =
            <div className="text-center">
                <h2>
                    You must connect your wallet to be able to mint
                    <br/>
                    <br/>
                    <button
                        className="btn btn-primary"
                        onClick={(event => {
                            event.preventDefault();
                            this.props.connectWallet(true);
                        })}
                    >
                        Connect Wallet
                    </button>
                </h2>
            </div>
        ;
        if (this.props.currentWallet != '0x0') {
            htmlMint =
                <div id="content" className="row">
                    <div className="col-12 text-center">
                        <h1>
                            MINT
                        </h1>

                        <h2>White List Mint</h2>

                        QTY
                        <select
                            onChange={(event => {

                                let qtyWhiteList = event.target.value;

                                this.setState({
                                    qtyWhiteList: qtyWhiteList
                                })
                            })}
                        >
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                        </select>

                        <br/>

                        <button className="btn btn-primary" onClick={(event => {
                            let qtyToMint = this.state.qtyWhiteList;
                            this.props.listMint(qtyToMint);
                        })}>List Mint With Crypto!</button>

                        {/*
                        <CrossmintPayButton
                            collectionTitle="Martian Wars"
                            collectionDescription="Martian Wars Collection"
                            clientId="17218380-16c7-4aa4-b5ae-1bc4acd8c920"
                            environment="staging"
                            mintConfig={{
                                count: this.state.qtyWhiteList,
                                wl: 1,
                                quantity: this.state.qtyWhiteList,
                                totalPrice: "1",
                                to: this.props.currentWallet,
                                // your custom minting arguments...
                            }}
                        />
                        */}

                        <br/>
                        <br/>


                        <h2>Public Mint</h2>

                        QTY
                        <select
                            onChange={(event => {

                                let qtyPublic = event.target.value;

                                this.setState({
                                    qtyPublic: qtyPublic
                                })
                            })}
                        >
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                        </select>

                        <br/>

                        <button className="btn btn-primary" onClick={(event => {
                            let qtyToMint = this.state.qtyPublic;
                            this.props.publicMint(qtyToMint);
                        })}>Public Mint With Crypto!</button>

                        {this.state.qtyPublic * window.web3.utils.fromWei(this.props.saleConfig.publicPrice)}

                        <CrossmintPayButton
                            collectionTitle="Martian Wars"
                            collectionDescription="Martian Wars Collection"
                            clientId="112f49ae-b66d-42f2-bf60-736d6e95b149"
                            environment="staging"
                            mintConfig=
                                {{
                                    "type":"erc-721",
                                    "totalPrice":"" + this.state.qtyPublic * window.web3.utils.fromWei(this.props.saleConfig.publicPrice) + "",
                                    "wl": 0,
                                    "quantity": this.state.qtyPublic
                                }}
                        />

                        {/*
                        {{
                                count: this.state.qtyPublic,
                                wl: 0,
                                quantity: this.state.qtyPublic,
                                totalPrice: "" + this.state.qtyPublic * window.web3.utils.fromWei(this.props.saleConfig.publicPrice) + "",
                                to: this.props.currentWallet,
                                // your custom minting arguments...
                            }}
                        */}


                        {/*
                    <CrossmintPayButton
                        collectionTitle="<TITLE_FOR_YOUR_COLLECTION>"
                        collectionDescription="<DESCRIPTION_OF_YOUR_COLLECTION>"
                        collectionPhoto="<OPT_URL_TO_PHOTO_COVER>"
                        clientId="<YOUR_CLIENT_ID>"
                        environment="<YOUR_DESIRED_ENVIRONMENT>"
                        mintConfig={{
                            count: "<NUMBER_OF_NFTS>",
                            totalPrice: "<SELECTED_PRICE>"
                            // your custom minting arguments...
                        }}
                    />

                    count: "1",
                    quantity: "1",
                    totalPrice: "0.2",
                    callerPublicSaleKey: 1111
                    */}



                        <strong>TEST CARD</strong><br/>
                        4242 4242 4242 4242 <br/>
                        12/34 <br/>
                        123


                    </div>
                </div>
            ;
        }

        return (
            htmlMint
        );
    }
}

export default Mint;
