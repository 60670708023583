import React, { Component } from 'react'
import {toast} from "react-toastify";


class Nfts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            idNft: null,
        }

        let url = new URL(window.location.href);
        let idNft = url.searchParams.get('idNft');

        if (idNft != null) {
            setTimeout(() => {
                this.openNft(idNft);
            }, 1000);
        }
    }

    openNft = (idNft) => {
        this.setState({
            idNft: idNft,
        })

        let fullUrl = window.location.protocol + '//' + window.location.host + window.location.pathname;
        if (idNft) {
            fullUrl = fullUrl + '?idNft=' + idNft;
        }

        window.history.pushState({'fullUrl': fullUrl},"", fullUrl);
    }

    getNftHtml = () => {
        let nftsHtml = [];
        let self = this;
        this.props.nfts.forEach(function(el) {
            nftsHtml.push(
                <a className="col-12 col-md-3 nftBox" href="javascript:;" onClick={(event => {
                    event.preventDefault();
                    self.openNft(el.id_nft);
                })}>
                    <img style={{width: '100%'}} src={"https://cristian.ovh/martian-war/nft/images/" + el.id_nft + ".png"} alt=""/>
                    <span>{el.name}</span>
                </a>
            );
        });

        return nftsHtml;
    }

    render() {

        let self = this;
        let htmlModalNFT = '';
        if (this.state.idNft) {
            this.props.getNftData(this.state.idNft);
            let nftData = this.props.getNftDataFromState(this.state.idNft);

            if (nftData != null) {

                let imageUrl = nftData.image;
                imageUrl = imageUrl.replace('ipfs://UPDATE/', 'https://cristian.ovh/martian-war/nft/images/');

                let dna = nftData.dna;
                let date = nftData.date;
                let attributes = nftData.attributes;

                let attributesHtml = [];
                attributes.forEach(function(el) {
                    attributesHtml.push(
                        <div className="col-12 col-md-6">
                            <div className="attributeLabel">{self.props.jsonFiltersNamesToHuman(el.trait_type)}:</div>
                            <div className="attributeValue">{self.props.jsonFiltersNamesToHuman(el.value)}</div>
                        </div>
                    );
                });

                htmlModalNFT = <div id="modalNftSingle" onClick={(event => {
                    //event.preventDefault();

                    if (event.target.id === 'modalNftSingle') {
                        this.openNft(null);
                    }

                })}>
                    <div id="modalNftSingleData">
                        <div className="row">
                            <div className="col-12 col-md-6 text-center">
                                <img style={{maxWidth: '100%'}} src={imageUrl} alt=""/>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="tokenNameContainer">
                                    #{this.state.idNft} <br/>
                                    {nftData.name}
                                </div>
                                <div className="tokenDnaContainer">
                                    DNA: {dna}
                                </div>
                                <div className="tokenDateContainer">
                                    Date: {date}
                                </div>
                                <div className="tokenAttributesContainer">
                                    <div className="row">
                                        {attributesHtml}
                                    </div>
                                </div>
                                <div className="tokenViewOnContainer text-right">
                                    <a target="_blank"
                                       href={"https://testnets.opensea.io/assets/rinkeby/" + this.props.MartianWarAddress + "/" + this.state.idNft}
                                       title="View on OpenSea"
                                       rel="noreferrer">
                                        OpenSea
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>;
            }
        }

        return (
            <>
                {htmlModalNFT}
                <div className="row">
                    {this.getNftHtml()}
                </div>
            </>
        );
    }
}

export default Nfts;
