import React, { Component } from 'react'
import Filter from './Filter'
import Nfts from './Nfts'

class Main extends Component {

    render() {
        return (
            <div id="content" className="row">
                <div className="col-md-3">
                    <Filter
                        filter={this.props.filters}
                        filtersChange={this.props.filtersChange.bind(this)}
                        jsonFiltersNamesToHuman={this.props.jsonFiltersNamesToHuman.bind(this)}
                    />
                </div>
                <div className="col-12 col-md-9">
                    <Nfts
                        nfts={this.props.nfts}
                        getNftData={this.props.getNftData.bind(this)}
                        getNftDataFromState={this.props.getNftDataFromState.bind(this)}
                        MartianWarAddress={this.props.MartianWarAddress}
                        jsonFiltersNamesToHuman={this.props.jsonFiltersNamesToHuman.bind(this)}
                    />
                </div>
            </div>
        );
    }
}

export default Main;
