import React, { Component } from 'react'

class Filter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            attributesOpen: [],
            mobileFilterDisplay: false,
        }
    }

    setOpenCloseAttribute = (idAttribute) => {
        let attributesOpen = this.state.attributesOpen;

        if (attributesOpen.hasOwnProperty(idAttribute)) {
            delete attributesOpen[idAttribute];
        } else {
            attributesOpen[idAttribute] = true;
        }

        this.setState({
            attributesOpen: attributesOpen,
        })
    }

    render() {

        let self = this;

        let filterHtml = [];
        let lastAttribute = '';
        this.props.filter.forEach(function(el) {

            if (lastAttribute != el.attributeName) {
                lastAttribute = el.attributeName;
                filterHtml.push(
                    <a href="javascript:;" className="attribute" onClick={(event => {
                        self.setOpenCloseAttribute(el.id_atribute)
                    })}>
                        {self.props.jsonFiltersNamesToHuman(el.attributeName)}

                        <span className="float-right">+</span>
                    </a>
                );
            }

            let classNameAttributeValue = 'd-none';

            if (self.state.attributesOpen.hasOwnProperty(el.id_atribute)) {
                classNameAttributeValue = 'd-inline-block';
            }

            filterHtml.push(
                <div style={{width: '100%'}} className={"attributeValue " + classNameAttributeValue}>
                    <input
                        id={'checkboxValueAttribute' + el.id_attribute_value}
                        className="attributeValue" value={el.id_attribute_value}
                        type="checkbox"
                        onChange={(event => {
                            self.props.filtersChange()
                        })}
                    />
                    &nbsp;
                    <label htmlFor={'checkboxValueAttribute' + el.id_attribute_value}>
                        {self.props.jsonFiltersNamesToHuman(el.attributeValue)}
                        <span className="attributeValueNNFTs">
                            ({self.props.jsonFiltersNamesToHuman(el.nNFTs)})
                        </span>
                    </label>
                </div>
            );
        });

        let cssFilterContainerMobile = 'd-none';
        if (this.state.mobileFilterDisplay) {
            cssFilterContainerMobile = 'd-block';
        }

        return (
            <>
                <div className="d-block d-md-none">
                    <a id="filterLinkIconMobile" href="javascript:;" onClick={(event => {
                        this.setState({
                            mobileFilterDisplay: !this.state.mobileFilterDisplay,
                        })
                    })}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"
                             className="w-5 h-5">
                            <path fill-rule="evenodd"
                                  d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                                  clip-rule="evenodd"></path>
                        </svg>
                        Filter
                    </a>
                </div>
                <div id="filterContainer" className={cssFilterContainerMobile + " d-md-block"}>
                    <h2>FILTER</h2>
                    {filterHtml}
                </div>
            </>
        );
    }
}

export default Filter;
