import React, { Component } from 'react'
import farmer from '../farmer.png'

import {BrowserRouter as Router, Link} from "react-router-dom";
import {Nav, Navbar} from 'react-bootstrap';


class Menu extends Component {

  render() {

      let logoutButton = '';

      let account = <button
          className="btn btn-primary"
          onClick={(event => {
              event.preventDefault();
              this.props.connectWallet(true);
          })}
      >
          Connect Wallet
      </button>;

      let currentWallet = this.props.currentWallet;

      if (currentWallet !== '0x0') {
          account = currentWallet.substring(0,6) + '...' + currentWallet.substr(currentWallet.length - 4);
          logoutButton = <button
              className="btn btn-primary"
              onClick={(event => {
                  event.preventDefault();
                  this.props.disconnectWallet();
              })}
          >
              Logout
          </button>
      }

    return (
        <Navbar id="menu" bg="light" expand="lg">
            <div className="menuBrand col-12 col-md-3">
                <Navbar.Brand>
                    <Link
                        className="navbar-brand col-sm-3 col-md-2 mr-0"
                        to="/">
                        Martian War
                    </Link>
                </Navbar.Brand>
            </div>

            <div className="menuLinks col-12 col-md-9">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav>
                        <Nav>
                            <Link to="/">Gallery</Link>
                        </Nav>
                        <Nav>
                            <Link to="/mint">Mint</Link>
                        </Nav>
                        <Nav id="account">
                            <div id="accountAddr">
                                {account}
                            </div>
                            <div id="loggoutContainer">
                                {logoutButton}
                            </div>
                        </Nav>
                    </Nav>
                </Navbar.Collapse>
            </div>

      </Navbar>
    );
  }
}

export default Menu;
